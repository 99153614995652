.card-details {
  display: flex;
  align-items: start;
  flex-direction: column;

  p {
    margin: 0;
  }
  
  .card-details-icon {
    align-self: start;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .card-details-header {
    display: flex;
    align-items: start;
    gap: 8px;
    width: 100%;
  
    .card-details-title {
      font-size: 16px;
      font-weight: 500;
      font-family: 'Inter', sans-serif;
      color: #101010;
      margin: 0;
    }
    
    .card-details-subtitle {
      font-size: 14px;
      font-weight: 400;
      font-family: 'Inter', sans-serif;
      color: #5F6778;
      margin: 0;
    }
  }
  
  .card-details-content {
    width: 100%;
  
    .card-details-description {
      overflow: hidden;
      text-overflow: ellipsis;
      height: auto;
      padding-left: 2rem;
      color: #5F6778;
    }
  }
  
  .content-toggle-button-flex-container {
    display: flex;
  }
  
  .content-toggle-button {
    margin-left: auto;
    text-decoration: underline;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 12px;
  }

  .description-toggle-button {
    display: block;
    margin-top: 8px;
    text-decoration: underline;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 12px;
  }

  .text-without-truncate {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    color: #5F6778;
  }
  
  .truncate-text {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    position: relative;
    word-break: break-all;
  }
}