.auth-layout {
    width: 100%;
    min-height: 100vh;
    overflow: auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    background-image: url('../../assets/img/images/auth-layout-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
}

.text {
    margin: 0 auto;
    text-align: center;
    font-size: 64px;
    font-weight: 600;
    line-height: 1.2;
    background-color: transparent;
    color: #fff;
}

.logo-img {
    width: 144px;
    height: 112px;
}
  
.logo-img-mobile {
    width: 278px;
    height: 54px;
}