.confirmEmail-text {
    margin: 0 auto;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #000000;
}

.confirmEmail-description {
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.5;
    color: #262626;
    margin-bottom: 1rem;
}

.divider-red {
    border: 1px solid #E9343C;
    background-color: #E9343C;
    opacity: 0.3;
    margin: 0 auto;
    width: 100%;
    height: 1px;
    margin-top: 15px;
    margin-bottom: 15px;
  
    &.hidden {
      display: none;
    }
}

.input-name {
    font-size: 14px;
    font-weight: 400;
    color: #000000 !important;
}

.bg-confirmEmail-btn {
    background-color: #E9343C;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 0.7rem 1rem;
    width: 100%;
    border-radius: 100px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
  
    &:hover {
      background-color: #E9343C;
      color: #fff;
    }
}

.google-btn {
    width: 100%;
    border: 1px solid #dee2e6 !important;
    border-radius: 0.25rem !important;
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05) !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.link-paragraph {
    font-size: 14px;
    font-weight: 400;
    color: #808080;

    small {
        padding: 0 4px;
        font-size: 14px;
        text-decoration-line: underline;
        font-weight: 500;
        color: #E9343C;
        cursor: pointer;
    }
}